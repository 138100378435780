// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-about-page-jsx": () => import("./../../../src/templates/about-page.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("./../../../src/templates/contact-page.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-index-page-jsx": () => import("./../../../src/templates/index-page.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */),
  "component---src-templates-mini-works-list-jsx": () => import("./../../../src/templates/mini-works-list.jsx" /* webpackChunkName: "component---src-templates-mini-works-list-jsx" */),
  "component---src-templates-project-list-jsx": () => import("./../../../src/templates/project-list.jsx" /* webpackChunkName: "component---src-templates-project-list-jsx" */)
}

